

import ContentTable from '../../components/StaffDetailTable.vue'
import { Course } from '@/models/course/classes/Course'
import { defineComponent, onMounted, ref, computed } from 'vue'
import { StaffMemberAPI } from '@/api/staffMember.api'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { Formats } from '@/utils/enums'

export default defineComponent({
  components: {
    ContentTable
  },
  props: {
    course: {
      type: Course,
      required: true
    }
  },
  setup (props) {
    const staff = ref<StaffMember[]>([])
    const loading = ref<boolean>(false)
    const course = computed<Course>(() => props.course)
    onMounted(async () => {
      try {
        loading.value = true
        staff.value = await StaffMemberAPI.findAll(course.value.id.toString(), Formats.Full)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })

    return {
      staff,
      loading
    }
  }
})
