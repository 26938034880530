
import { computed, defineComponent, PropType, ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { CSSColors } from '@/models/enums'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { PersonRoles } from '@/models/person/enums'

export default defineComponent({
  props: {
    staffMembers: {
      type: Array as PropType<StaffMember[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const staffMembers = computed<StaffMember[]>(() => props.staffMembers)
    const teachers = computed<StaffMember[]>(() => (staffMembers.value as unknown as StaffMember[]).filter(el => el.user?.role === PersonRoles.Teacher || el.user?.role === PersonRoles.Coordinator))
    const tutors = computed<StaffMember[]>(() => (staffMembers.value as unknown as StaffMember[]).filter(el => el.user?.role === PersonRoles.Tutor))
    return {
      StaffMember,
      CSSColors,
      teachers,
      tutors,
      filters: ref({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } })
    }
  }
})
